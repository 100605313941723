import React from "react";
import {
  BsTwitter,
  BsInstagram,
  BsGithub,
  BsWhatsapp,
  BsLinkedin,
} from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://github.com/AsKing07" target="blank">
        <BsGithub />
      </a>
    </div>
    <div>
      <a
        href="https://www.linkedin.com/in/charbel-sonon-1a121223b/"
        target="blank"
      >
        <BsLinkedin />
      </a>
    </div>
    <div>
      <a
        href="https://api.whatsapp.com/send?phone=22965861948&text=Hello%20Charbel,%20%20I%20was%20impressed%20by%20your%20portfolio%20and%20the%20range%20of%20skills%20you%20possess.%20I'm%20interested%20in%20discussing%20a%20potential%20project%20with%20you.%20Please%20let%20me%20know%20when%20you're%20available%20for%20a%20conversation.%20%20Best%20regards"
        target="blank"
      >
        <BsWhatsapp />
      </a>
    </div>
    <div>
      <a href="https://www.facebook.com/charbel.Asking" target="blank">
        <FaFacebookF />
      </a>
    </div>
  </div>
);

export default SocialMedia;
